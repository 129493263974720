import { toast } from 'react-toastify'
import { useMemo } from 'react'

const useToast = () => {
    return useMemo(() => {
        return toast
    }, [])
}

export default useToast
