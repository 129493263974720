import { IconProps } from './Icon.types'
import React from 'react'

const OrderIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[20px] w-[20px]'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.4062 13.6977L17.9162 9.18772L16.9218 8.19336L13.4062 11.709L12 10.3027L11.0056 11.2971L13.4062 13.6977Z'
                fill={fill}
            />
            <path
                d='M19.0312 17.625H7.78125C7.39355 17.625 7.07812 17.3096 7.07812 16.9219C7.07812 16.5342 7.39355 16.2188 7.78125 16.2188H20.7803L24 4.96875H5.90836L5.61075 3.76013L5.61023 3.76027C5.38317 2.84048 4.55142 2.15625 3.5625 2.15625H2.10938C0.946266 2.15625 0 3.10252 0 4.26562H1.40625C1.40625 3.87792 1.72167 3.5625 2.10938 3.5625H3.5625C3.9502 3.5625 4.26562 3.87792 4.26562 4.26562H4.287L6.9285 14.9932C6.18909 15.3215 5.67188 16.0622 5.67188 16.9219C5.67188 18.085 6.61814 19.0312 7.78125 19.0312H8.60531C8.52727 19.2513 8.48438 19.4879 8.48438 19.7344C8.48438 20.8975 9.43064 21.8438 10.5938 21.8438C11.7569 21.8438 12.7031 20.8975 12.7031 19.7344C12.7031 19.4879 12.6602 19.2513 12.5822 19.0312H17.0429C16.9648 19.2513 16.9219 19.4879 16.9219 19.7344C16.9219 20.8975 17.8682 21.8438 19.0313 21.8438C20.1944 21.8438 21.1407 20.8975 21.1407 19.7344C21.1407 18.5713 20.1944 17.625 19.0312 17.625ZM22.1357 6.375L19.7197 14.8125H8.33227L6.25467 6.375H22.1357ZM11.2969 19.7344C11.2969 20.1221 10.9815 20.4375 10.5938 20.4375C10.206 20.4375 9.89062 20.1221 9.89062 19.7344C9.89062 19.3467 10.206 19.0312 10.5938 19.0312C10.9815 19.0312 11.2969 19.3467 11.2969 19.7344ZM19.0312 20.4375C18.6435 20.4375 18.3281 20.1221 18.3281 19.7344C18.3281 19.3467 18.6435 19.0312 19.0312 19.0312C19.419 19.0312 19.7344 19.3467 19.7344 19.7344C19.7344 20.1221 19.419 20.4375 19.0312 20.4375Z'
                fill={fill}
            />
        </svg>
    )
}

export default OrderIcon
