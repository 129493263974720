import { CalculatorIcon } from '@heroicons/react/outline'
import { OrderStatus, PriceOffer } from '../../services/api/order/OrderService.types'
import { SidebarItem } from '../dashboard/sidebar/Sidebar'
import { buildClassesWithDefault } from '../../utils/StyleHelper'
import { useTranslation } from 'next-i18next'
import AppBar, { AppBarProps } from '../dashboard/app-bar/AppBar'
import Button from '../base/button/Button'
import Change from '../base/icon/ChangeIcon'
import Column from '../base/grid/Column'
import Complaint from '../base/icon/ComplaintIcon'
import Demand from '../base/icon/DemandIcon'
import Download from '../base/icon/DownloadIcon'
import Help from '../base/icon/HelpIcon'
import Home from '../base/icon/HomeIcon'
import IdeaIcon from '../base/icon/IdeaIcon'
import Invoice from '../base/icon/InvoiceIcon'
import Layout, { LayoutProps } from './Layout'
import Link from 'next/link'
import Loading from '../base/loading/Loading'
import MobileSidebar from '../dashboard/sidebar/MobileSidebar'
import Order from '../base/icon/OrderIcon'
import PostInfoCardList from '../dashboard/posts/PostInfoCardList'
import React, { PropsWithChildren, ReactNode, useMemo } from 'react'
import Row from '../base/grid/Row'
import useMiddleware from '../../hooks/useMiddleware'
import useTheme from '../../hooks/useTheme'

export type PrimaryTitle = 'appbar' | 'layout'

export type DashboardLayoutProps = LayoutProps & {
    dashboardTitle?: ReactNode
    appBarContent?: ReactNode
    NavigationLine?: ReactNode
    appBarProps?: AppBarProps
    primaryTitle?: PrimaryTitle
    showAppBar?: boolean
    showPosts?: boolean
    sidebar?: ReactNode
    footer?: JSX.Element
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
}

const DashboardLayout = ({
    children,
    dashboardTitle,
    appBarContent,
    NavigationLine,
    appBarProps,
    showAppBar = true,
    showPosts = true,
    primaryTitle = 'appbar',
    sidebar,
    footer,
    demandStatus,
    priceOffer,
    ...props
}: PropsWithChildren<DashboardLayoutProps>) => {
    const { t } = useTranslation()
    const { openedSidebar } = useTheme()
    const { isInitialized } = useMiddleware(true)

    const navigation = useMemo<SidebarItem[]>(() => {
        return [
            {
                name: t('frontend.sidebar.items.home'),
                href: '/app',
                icon: Home
            },
            {
                name: t('frontend.sidebar.items.demands'),
                href: '/app/demand',
                icon: Demand
            },
            {
                name: t('frontend.sidebar.items.orders'),
                href: '/app/order',
                icon: Order
            },
            {
                name: t('frontend.sidebar.items.price_offers'),
                href: '/app/price-offers',
                icon: Invoice
            },
            {
                show: false,
                name: t('frontend.sidebar.items.mpvz_calculator'),
                href: '/app/mpvz-calculator',
                icon: CalculatorIcon
            },
            {
                name: t('frontend.sidebar.items.complaints_and_repairs'),
                href: '/app/complaints-and-repairs',
                icon: Complaint
            },
            {
                name: t('frontend.sidebar.items.assortment_updates'),
                href: '/app/assortment-updates',
                icon: Change
            },
            {
                name: t('frontend.sidebar.items.download'),
                href: '/app/download',
                icon: Download
            },
            {
                name: 'Nápady na vylepšení',
                href: '/app/ideas',
                icon: IdeaIcon
            },

            {
                name: t('frontend.sidebar.items.help'),
                href: '/app/help',
                icon: Help
            }
        ]
    }, [t])

    const renderAppBar = () => {
        const title = primaryTitle === 'appbar' ? dashboardTitle || props.title : ' '

        if (showAppBar) {
            return (
                <AppBar title={title} demandStatus={demandStatus} priceOffer={priceOffer} {...appBarProps}>
                    {appBarContent}
                </AppBar>
            )
        }
    }

    const renderContent = () => {
        return (
            <>
                {NavigationLine}
                <main className={`px-4 py-3 ${NavigationLine && 'mt-[60px]'} bg-gray-100`}>
                    <div className='mx-auto px-4'>
                        {primaryTitle === 'layout' && <h1 className='page-subtitle'>{props.title}</h1>}
                        {primaryTitle === 'layout' && <h1 className='page-subtitle'>{props.title}</h1>}
                    </div>
                    <Row className='w-full relative '>
                        <Column cols={12} xl={showPosts ? 8 : 12} xl2={showPosts ? 9 : 12}>
                            {children}
                        </Column>
                        {showPosts && (
                            <Column
                                cols={12}
                                xl={4}
                                xl2={3}
                                className={buildClassesWithDefault(
                                    {
                                        '!right-0 !w-full xl:pl-[55px]': openedSidebar
                                    },
                                    'xl:fixed right-[10px] w-full h-[calc(100%-5rem)] overflow-y-scroll mt-[-16px] pt-[0px] no-scrollbar'
                                )}
                            >
                                <div className='h-full w-full relative mt-[22px] overflow-y-visible'>
                                    <aside className='w-full h-full overflow-y-visible xl:absolute '>
                                        <PostInfoCardList />
                                        <div className='hidden w-full xl:flex justify-center xl:sticky xl:bottom-0 xl:right-3'>
                                            <div className='flex xl:h-[40px] xl:mb-[-15px] xl:w-full xl:bg-gray-100 xl:blur'></div>
                                            <Button
                                                as={Link}
                                                href='/app/news'
                                                color='secondary'
                                                variant='outlined'
                                                className='h-[41px] xl:fixed xl:bottom-4 xl:right-40   '
                                            >
                                                {t('frontend.dashboard.demand.table.actions.show_all')}
                                            </Button>
                                        </div>
                                    </aside>
                                </div>
                            </Column>
                        )}
                    </Row>
                </main>
            </>
        )
    }

    const renderLoading = () => {
        if (!isInitialized) {
            return (
                <div className='w-full min-h-screen flex justify-center items-center'>
                    <Loading className='w-24 h-24' />
                </div>
            )
        }
    }

    return (
        <Layout {...props}>
            {isInitialized && (
                <>
                    {renderAppBar()}
                    <MobileSidebar items={navigation}>{sidebar}</MobileSidebar>
                    <div
                        className={buildClassesWithDefault(
                            {
                                'md:pl-[229px] pr-4': openedSidebar
                            },
                            'flex flex-col justify-between min-h-screen mt-20 transition-all duration-300 bg-gray-100'
                        )}
                    >
                        {renderContent()}
                        {footer}
                    </div>
                </>
            )}
            {renderLoading()}
        </Layout>
    )
}

export default DashboardLayout
