import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { MakeAxiosPaginatedResponse, MakeAxiosResponse } from '../../Service.types'
import {
    Order,
    OrderCopyRequest,
    OrderCreateRequest,
    OrderDelivery,
    OrderDiscounts,
    OrderInTotal,
    OrderItem,
    OrderItemRequest,
    OrderSaveRequest,
    OrderScenario,
    ShortOrder
} from './OrderService.types'
import { removeUndefinedValuesFromObject } from '../../../utils/ObjectHelper'

export const getOrderPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<ShortOrder>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<Order>>(`order${query}`, config)
    return data
}

export const getOrder = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<Order> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data: order }
    } = await axiosInstance.get<MakeAxiosResponse<Order>>(`order/${id}`, {
        ...config,
        headers
    })
    return order
}

export const getOrderDelivery = async (
    axiosInstance: AxiosInstance,
    id: number,
    date: string,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderDelivery[]> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    config = {
        ...config,
        headers,
        params: removeUndefinedValuesFromObject({
            ...(config?.params || {}),
            date: date?.length ? date : undefined
        })
    }

    const {
        data: { data: orderDelivery }
    } = await axiosInstance.get<MakeAxiosResponse<OrderDelivery[]>>(`order/${id}/delivery-date`, config)
    return orderDelivery
}

export const createOrder = async (
    axiosInstance: AxiosInstance,
    request: OrderCreateRequest,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data: order }
    } = await axiosInstance.post<MakeAxiosResponse<Order>>('order', request, config)
    return order
}

export const copyOrder = async (
    axiosInstance: AxiosInstance,
    request: OrderCopyRequest,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data: order }
    } = await axiosInstance.post<MakeAxiosResponse<Order>>('order/copy', request, config)
    return order
}

export const deleteOrder = async (axiosInstance: AxiosInstance, id: number, config: AxiosRequestConfig = {}) => {
    return axiosInstance.delete(`order/${id}`, config)
}

export const discardOderChanges = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data: order }
    } = await axiosInstance.delete(`order/${id}/discard-changes`, config)
    return order
}

export const refreshOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    initData: boolean,
    request: OrderItemRequest,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderItem> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    config = {
        ...config,
        headers,
        params: {
            ...(config.params || {}),
            initData: initData ? 1 : 0
        }
    }

    const {
        data: { data: orderItem }
    } = await axiosInstance.post<MakeAxiosResponse<OrderItem>>(`order/${orderId}/item/refresh`, request, config)
    return orderItem
}

export const createOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    request: OrderItemRequest,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderItem> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data: orderItem }
    } = await axiosInstance.post<MakeAxiosResponse<OrderItem>>(`order/${orderId}/item`, request, {
        ...config,
        headers
    })
    return orderItem
}

export const refreshExistingOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    orderItemId: number,
    request: OrderItemRequest,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderItem> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data: orderItem }
    } = await axiosInstance.post<MakeAxiosResponse<OrderItem>>(
        `order/${orderId}/item/${orderItemId}/refresh`,
        request,
        {
            ...config,
            headers
        }
    )
    return orderItem
}

export const updateOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    orderItemId: number,
    request: OrderItemRequest,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderItem> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data: orderItem }
    } = await axiosInstance.patch<MakeAxiosResponse<OrderItem>>(`order/${orderId}/item/${orderItemId}`, request, {
        ...config,
        headers
    })
    return orderItem
}

export const deleteOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    orderItemId: number,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
) => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    return axiosInstance.delete(`order/${orderId}/item/${orderItemId}`, { ...config, headers })
}

export const forceDeleteOrderItem = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    orderItemId: number,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<number[]> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data }
    } = await axiosInstance.delete<MakeAxiosResponse<number[]>>(`order/${orderId}/item/${orderItemId}/force`, {
        ...config,
        headers
    })
    return data
}

export const getOrderInTotal = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    config: AxiosRequestConfig = {},
    scenario?: OrderScenario
): Promise<OrderInTotal> => {
    let headers = config.headers || {}
    if (scenario) {
        headers = {
            ...headers,
            scenario
        }
    }
    const {
        data: { data: orderInTotal }
    } = await axiosInstance.get<MakeAxiosResponse<OrderInTotal>>(`order/${orderId}/in-total`, { ...config, headers })
    return orderInTotal
}

export const getOrderDiscounts = async (
    axiosInstance: AxiosInstance,
    orderId: number,
    config: AxiosRequestConfig = {}
): Promise<OrderDiscounts[]> => {
    const {
        data: { data: orderDiscounts }
    } = await axiosInstance.get<MakeAxiosResponse<OrderDiscounts[]>>(`order/${orderId}/discounts`, config)
    return orderDiscounts
}

export const orderSaveAsDraft = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: OrderSaveRequest,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data }
    } = await axiosInstance.patch(`order/${id}/save-as-draft`, request, config)
    return data
}

export const orderSaveAsFinished = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: OrderSaveRequest,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data }
    } = await axiosInstance.patch(`order/${id}/save-as-finished`, request, config)
    return data
}

export const orderFinishAndCreateCoverPlate = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: OrderSaveRequest,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data }
    } = await axiosInstance.patch(`order/${id}/finish-and-create-cover-plate`, request, config)
    return data
}

export const disconnectOrder = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data: order }
    } = await axiosInstance.patch(`order/${id}/disconnect`, config)
    return order
}

export const orderSendToProduction = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: Pick<OrderSaveRequest, 'tsDelivery' | 'expedition' | 'expeditionN' | 'dpdPay'>,
    config: AxiosRequestConfig = {}
): Promise<Order> => {
    const {
        data: { data }
    } = await axiosInstance.patch(`order/${id}/send-to-production`, request, config)
    return data
}

export const getOrderProductImage = async (
    axiosInstance: AxiosInstance,
    fileUrl: string,
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: config.responseType || 'blob'
    }

    const { data } = await axiosInstance.get<Blob>(fileUrl, config)

    return data
}
