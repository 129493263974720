import {
    CSSProperties,
    ForwardedRef,
    HTMLAttributes,
    ReactNode,
    Ref,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import {
    FloatingPortal,
    Placement,
    Strategy,
    arrow,
    autoUpdate,
    hide,
    offset,
    safePolygon,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles
} from '@floating-ui/react'
import { Theme } from '../../../services/Service.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import useTheme from '../../../hooks/useTheme'

export type TooltipRef = {
    open: () => void
    close: () => void
}

export type TooltipChildren =
    | ((ref: Ref<HTMLElement>, props: Record<string, unknown>, disabled: boolean) => ReactNode)
    | ReactNode

export type TooltipProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
    content: ((ref: Ref<HTMLElement>, props: Record<string, unknown>) => ReactNode) | ReactNode
    children: TooltipChildren
    placement?: Placement
    strategy?: Strategy
    contentClassName?: string
    zIndex?: number
    portalTarget?: HTMLElement
    backgroundColor?: Path<Theme['colors']> | string
    textColor?: Path<Theme['colors']> | string
    disabled?: boolean
    disableAutoHide?: boolean
    lazy?: boolean
}

const Tooltip = (
    {
        className,
        children,
        content,
        placement = 'top',
        strategy: strategyProp = 'absolute',
        contentClassName,
        zIndex = 30,
        portalTarget,
        backgroundColor = '#fefce8',
        textColor = 'gray.600',
        disabled,
        disableAutoHide,
        lazy = true,
        ...props
    }: TooltipProps,
    ref: ForwardedRef<TooltipRef>
) => {
    const { getColor } = useTheme()
    const [open, setOpen] = useState(false)
    const arrowRef = useRef<HTMLDivElement>(null)

    const {
        x,
        y,
        refs,
        strategy,
        context,
        middlewareData: { arrow: { x: arrowX, y: arrowY } = {}, hide: hideMiddleware },
        placement: floatingPlacement
    } = useFloating({
        open: open,
        onOpenChange: value => {
            return disabled ? setOpen(false) : setOpen(disableAutoHide || value)
        },
        placement: placement,
        strategy: strategyProp,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            shift(),
            arrow({
                element: arrowRef.current
            }),
            hide()
        ]
    })

    const { isMounted, styles } = useTransitionStyles(context)
    const hover = useHover(context, { move: false, handleClose: safePolygon() })
    const focus = useFocus(context)
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: 'tooltip' })

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

    useImperativeHandle(ref, () => {
        return {
            open() {
                setOpen(true)
            },
            close() {
                setOpen(false)
            }
        }
    })

    const tooltipStyles: CSSProperties = {
        zIndex,
        display:
            (isMounted && hideMiddleware && !hideMiddleware.escaped && !hideMiddleware.referenceHidden) || lazy
                ? 'block'
                : 'none',
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        color: getColor(textColor) || textColor,
        backgroundColor: getColor(backgroundColor) || backgroundColor,
        ...styles
    }

    const getAllReferenceProps = () => {
        return {
            ...getReferenceProps(),
            ref: refs.setReference
        }
    }

    const arrowSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right'
    }[floatingPlacement.split('-')[0]]

    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(refs.setReference, getReferenceProps(), disabled)
        }

        return (
            <div {...props} {...getAllReferenceProps()} className={buildClassesWithDefault('inline-block', className)}>
                {children}
            </div>
        )
    }

    const renderTooltipContent = () => {
        if (disabled || (lazy && !isMounted)) {
            return
        }
        if (typeof content === 'function') {
            return content(refs.setFloating, {
                style: tooltipStyles,
                ...getFloatingProps()
            })
        }

        return (
            <div
                className={buildClassesWithDefault('tooltip', contentClassName)}
                ref={refs.setFloating}
                style={tooltipStyles}
                {...getFloatingProps()}
            >
                <span
                    className='relative z-10'
                    {...{ dangerouslySetInnerHTML: typeof content === 'string' ? { __html: content } : undefined }}
                >
                    {typeof content !== 'string' ? content : null}
                </span>
                <div
                    ref={arrowRef}
                    style={{
                        position: 'absolute',
                        width: '10px',
                        height: '10px',
                        backgroundColor: getColor(backgroundColor) || backgroundColor,
                        left: arrowX ?? `${arrowX}px`,
                        top: arrowY ?? `${arrowY}px`,
                        [arrowSide]: '-5px',
                        transform: 'rotate(45deg)'
                    }}
                />
            </div>
        )
    }

    return (
        <>
            <FloatingPortal root={portalTarget}>{renderTooltipContent()}</FloatingPortal>
            {renderChildren()}
        </>
    )
}

export default forwardRef(Tooltip)
