import { IconProps } from './Icon.types'
import React from 'react'

const HelpIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[20px] w-[20px]'
            width='21'
            height='22'
            viewBox='0 0 21 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.4839 2.2627C15.3089 2.2627 19.2205 6.17419 19.2205 10.9993C19.2205 15.8243 15.3089 19.7358 10.4839 19.7358C5.65881 19.7358 1.74731 15.8243 1.74731 10.9993C1.74731 6.17419 5.65881 2.2627 10.4839 2.2627ZM10.4839 4.01001C6.62383 4.01001 3.49463 7.13921 3.49463 10.9993C3.49463 14.8594 6.62383 17.9885 10.4839 17.9885C14.344 17.9885 17.4732 14.8594 17.4732 10.9993C17.4732 7.13921 14.344 4.01001 10.4839 4.01001ZM10.4839 14.4939C10.9664 14.4939 11.3575 14.885 11.3575 15.3676C11.3575 15.8501 10.9664 16.2412 10.4839 16.2412C10.0014 16.2412 9.61023 15.8501 9.61023 15.3676C9.61023 14.885 10.0014 14.4939 10.4839 14.4939ZM10.4839 5.75733C11.3316 5.75733 12.1922 5.96128 12.863 6.47039C13.5652 7.00318 13.9785 7.81163 13.9785 8.81513C13.9785 10.0661 13.1309 10.8516 12.5633 11.3776L12.5436 11.3957C11.8941 11.9977 11.4741 12.3869 11.3315 12.9581C11.2147 13.4263 10.7405 13.7111 10.2723 13.5942C9.80419 13.4774 9.51939 13.0032 9.63623 12.5351C9.90911 11.4416 10.6956 10.7201 11.2443 10.2168C11.2858 10.1788 11.3258 10.142 11.3643 10.1064C11.998 9.5191 12.2312 9.2158 12.2312 8.81513C12.2312 8.33411 12.0548 8.05048 11.8069 7.86233C11.5276 7.65049 11.0777 7.50464 10.4839 7.50464C9.52486 7.50464 8.73658 8.29292 8.73658 9.25196C8.73658 9.73446 8.34542 10.1256 7.86292 10.1256C7.38041 10.1256 6.98926 9.73446 6.98926 9.25196C6.98926 7.32792 8.55985 5.75733 10.4839 5.75733Z'
                fill={fill}
            />
        </svg>
    )
}

export default HelpIcon
