import createAuthentication from '@dactyl-npm-packages/dactyl-auth-react'

export type User = {
    id: number
    login: string
    role: string
    firstName: string
    lastName: string
    email: string
    company: string
    ico: string
    language: string
    logo: string
    specialType: string
}

export type LoginRequestData = {
    username: string
    password: string
}

export type UpdateUserPasswordRequestData = {
    oldPassword: string
    newPassword: string
}

const { AuthContextProvider, useCurrentUser, useSignIn, useSignOut, useCurrentUserUpdatePassword, Authenticated } =
    createAuthentication<User>()

export { AuthContextProvider, useCurrentUser, useSignIn, useSignOut, useCurrentUserUpdatePassword, Authenticated }
