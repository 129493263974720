import { IconProps } from './Icon.types'
import React from 'react'

const ChangeIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3.5 h-[18px] w-[18px]'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_11584_135446)'>
                <path
                    d='M19.2897 2.75894C18.8599 2.71977 18.4799 3.03637 18.4407 3.46605L18.3372 4.60156C16.581 1.84352 13.5324 0 10 0C6.19997 0 2.8511 2.16883 1.18868 5.42398C0.99243 5.80824 1.14485 6.27883 1.52911 6.47508C1.91345 6.67133 2.38395 6.51894 2.5802 6.13465C3.98712 3.3798 6.81239 1.5625 10 1.5625C12.9054 1.5625 15.5273 3.07543 17.0294 5.44562L15.9155 4.65004C15.5643 4.3993 15.0764 4.48062 14.8257 4.83172C14.5749 5.18285 14.6563 5.67074 15.0074 5.92152L18.3853 8.33406C18.8804 8.68699 19.5632 8.36391 19.6174 7.76926L19.9967 3.60793C20.0359 3.1782 19.7193 2.79812 19.2897 2.75894Z'
                    fill={fill}
                />
                <path
                    d='M18.4709 13.5254C18.0866 13.3292 17.6161 13.4816 17.4198 13.8658C16.0129 16.6207 13.1876 18.438 10 18.438C7.09467 18.438 4.47271 16.9251 2.9706 14.5549L4.08455 15.3504C4.43568 15.6012 4.92357 15.5199 5.17435 15.1688C5.4251 14.8176 5.34377 14.3297 4.99267 14.079L1.61475 11.6664C1.12572 11.317 0.437362 11.6304 0.382675 12.2312L0.00333868 16.3925C-0.035841 16.8222 0.280761 17.2023 0.710409 17.2415C1.14064 17.2806 1.52021 16.9637 1.55935 16.5344L1.66287 15.3989C3.41904 18.157 6.46764 20.0005 10 20.0005C13.8001 20.0005 17.1489 17.8317 18.8113 14.5765C19.0076 14.1922 18.8552 13.7217 18.4709 13.5254Z'
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id='clip0_11584_135446'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ChangeIcon
