import { IconProps } from './Icon.types'
import React from 'react'

const IdeaIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[20px] w-[20px]'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_12675_617)'>
                <path
                    d='M11 3.42327C11.3393 3.42327 11.6143 3.14823 11.6143 2.80898V1.12992C11.6143 0.790663 11.3393 0.515625 11 0.515625C10.6608 0.515625 10.3857 0.790663 10.3857 1.12992V2.80898C10.3857 3.14823 10.6608 3.42327 11 3.42327Z'
                    fill={fill}
                />
                <path
                    d='M17.9861 2.70138C17.7462 2.46152 17.3573 2.46152 17.1174 2.70138L15.8069 4.01187C15.567 4.25173 15.567 4.64069 15.8069 4.8806C16.0468 5.1205 16.4357 5.1205 16.6756 4.8806L17.9861 3.57011C18.226 3.33021 18.226 2.94124 17.9861 2.70138Z'
                    fill={fill}
                />
                <path
                    d='M6.19215 4.8806C6.43205 4.64074 6.43205 4.25177 6.19215 4.01187L4.88166 2.70138C4.6418 2.46152 4.25283 2.46152 4.01293 2.70138C3.77303 2.94124 3.77303 3.33021 4.01293 3.57011L5.32342 4.8806C5.56336 5.12054 5.95229 5.1205 6.19215 4.8806Z'
                    fill={fill}
                />
                <path
                    d='M10.9993 4.81641C7.58948 4.81641 4.81543 7.59046 4.81543 11.0003C4.81543 14.1615 7.76402 15.7089 7.76402 17.5527V19.5594C7.76402 20.6207 8.62747 21.4842 9.6888 21.4842H12.3098C13.3711 21.4842 14.2346 20.6207 14.2346 19.5594V17.5527C14.2346 15.7083 17.1831 14.1628 17.1831 11.0003C17.1831 7.59046 14.4091 4.81641 10.9993 4.81641ZM12.3098 20.2556H9.6888C9.30491 20.2556 8.99261 19.9433 8.99261 19.5594V18.167H13.006V19.5594C13.006 19.9433 12.6937 20.2556 12.3098 20.2556ZM13.0694 16.9384H11.6136V14.462C12.3745 14.205 12.9241 13.4849 12.9241 12.6384C12.9241 12.2991 12.649 12.0241 12.3098 12.0241C11.9705 12.0241 11.6955 12.2991 11.6955 12.6384C11.6955 13.0223 11.3832 13.3346 10.9993 13.3346C10.6154 13.3346 10.3031 13.0223 10.3031 12.6384C10.3031 12.2991 10.0281 12.0241 9.6888 12.0241C9.34955 12.0241 9.07451 12.2991 9.07451 12.6384C9.07451 13.4849 9.6241 14.205 10.385 14.462V16.9384H8.92913C8.49609 14.826 6.04401 13.4699 6.04401 11.0003C6.04401 8.2679 8.26692 6.04499 10.9993 6.04499C13.7317 6.04499 15.9546 8.2679 15.9546 11.0003C15.9546 13.4678 13.5019 14.8285 13.0694 16.9384Z'
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id='clip0_12675_617'>
                    <rect width='20.9678' height='20.9678' fill='white' transform='translate(0.515625 0.515625)' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IdeaIcon
