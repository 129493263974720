import { useTranslation } from 'next-i18next'
import Button from '../../base/button/Button'
import Link from 'next/link'
import React from 'react'
import SearchTextField from '../search/SearchTextField'

const AppBarMenu = () => {
    const { t } = useTranslation()

    return (
        <>
            {/* Computer version */}
            <div className='items-center flex space-x-4 mr-3 xl:mr-0'>
                <Button className='mx-1 h-[41px] !rounded-lg' as={Link} href='/app/demand/create'>
                    {t('frontend.dashboard.shared.appbar.new_demand')}
                </Button>
                <SearchTextField />
            </div>
        </>
    )
}

export default AppBarMenu
