import { IconProps } from './Icon.types'
import React from 'react'

const DownloadIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[24px] w-[20px]'
            width='24'
            height='28'
            viewBox='0 0 24 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4 18L4 19C4 20.6569 5.34315 22 7 22L17 22C18.6569 22 20 20.6569 20 19L20 18M16 14L12 18M12 18L8 14M12 18L12 6'
                stroke={fill}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default DownloadIcon
