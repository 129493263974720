import { IconProps } from './Icon.types'
import React from 'react'

const HomeIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[20px] w-[20px]'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_11584_124008)'>
                <path
                    d='M23.4 10.392C23.3992 10.3914 23.3987 10.3907 23.3981 10.3902L13.608 0.600404C13.1907 0.182923 12.6359 -0.046875 12.0458 -0.046875C11.4556 -0.046875 10.9008 0.182923 10.4833 0.600404L0.698329 10.3852C0.695033 10.3885 0.691554 10.392 0.688441 10.3953C-0.168494 11.2572 -0.167029 12.6555 0.692653 13.5152C1.08541 13.9082 1.60397 14.1356 2.1586 14.1596C2.1813 14.1618 2.20401 14.1629 2.2269 14.1629H2.61691V21.3674C2.61691 22.7932 3.77707 23.9532 5.20292 23.9532H9.03312C9.42149 23.9532 9.73625 23.6382 9.73625 23.25V17.6016C9.73625 16.951 10.2656 16.4218 10.9162 16.4218H13.1753C13.8259 16.4218 14.3551 16.951 14.3551 17.6016V23.25C14.3551 23.6382 14.6699 23.9532 15.0582 23.9532H18.8884C20.3145 23.9532 21.4744 22.7932 21.4744 21.3674V14.1629H21.8362C22.4262 14.1629 22.981 13.9331 23.3987 13.5154C24.2593 12.6544 24.2597 11.2535 23.4 10.392ZM22.4042 12.5211C22.2524 12.6729 22.0507 12.7566 21.8362 12.7566H20.7713C20.3829 12.7566 20.0682 13.0714 20.0682 13.4597V21.3674C20.0682 22.0177 19.539 22.5469 18.8884 22.5469H15.7613V17.6016C15.7613 16.1758 14.6014 15.0156 13.1753 15.0156H10.9162C9.49016 15.0156 8.33 16.1758 8.33 17.6016V22.5469H5.20292C4.55252 22.5469 4.02316 22.0177 4.02316 21.3674V13.4597C4.02316 13.0714 3.70841 12.7566 3.32004 12.7566H2.27341C2.26242 12.7559 2.25162 12.7553 2.24045 12.7552C2.03097 12.7515 1.8345 12.6684 1.68728 12.521C1.37417 12.2079 1.37417 11.6983 1.68728 11.385C1.68747 11.385 1.68747 11.3848 1.68765 11.3846L1.6882 11.3841L11.478 1.59467C11.6296 1.44287 11.8312 1.35938 12.0458 1.35938C12.2602 1.35938 12.4618 1.44287 12.6136 1.59467L22.4011 11.382C22.4026 11.3835 22.4042 11.385 22.4057 11.3864C22.7172 11.7001 22.7166 12.2086 22.4042 12.5211Z'
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id='clip0_11584_124008'>
                    <rect width='24' height='24' fill={fill} />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HomeIcon
