import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link'
import { Link as ScrollLink } from 'react-scroll'
import { SidebarItem } from './Sidebar'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { useRouter } from 'next/router'
import React from 'react'

export type SidebarListItemProps = {
    item: SidebarItem
}

const showItemIcon = true

const SidebarListItem = ({
    item: { href, name, icon: Icon, navigation, onClick, active: isItemActive = false, scrollableLink }
}: SidebarListItemProps) => {
    const { pathname, push: routerPush, reload: routerReload } = useRouter()

    const isActiveLink = (path: string) => {
        const pathnameParts = pathname.split('/')
        const pathParts = path.split('/')

        return pathname === path || pathnameParts[2] === pathParts[2] || isItemActive
    }

    const redirectTo = (link: string) => {
        if (link === pathname) {
            routerReload()
            return
        }
        routerPush(link)
    }

    const renderItem = () => {
        const showIcon = Icon && showItemIcon

        if (href) {
            const classes = {
                active: isActiveLink(href),
                inactive: !isActiveLink(href),
                item: true
            }
            return (
                <a
                    href={href}
                    onClick={event => {
                        event.preventDefault()
                        redirectTo(href)
                    }}
                    className={buildClassesWithDefault(classes, 'group mb-2.5')}
                >
                    {showIcon && (
                        <Icon className='icon' fill={isActiveLink(href) ? 'white' : 'black'} width={20} height={20} />
                    )}
                    {name}
                </a>
            )
        }
        if (scrollableLink) {
            return (
                <ScrollLink
                    className='group item inactive hover:cursor-pointer mb-3'
                    {...(scrollableLink as ReactScrollLinkProps)}
                    activeClass='active'
                >
                    {showIcon && <Icon className='icon' fill='black' />}
                    {name}
                </ScrollLink>
            )
        }
        return (
            <a onClick={onClick} className='item inactive group mb-3'>
                {showIcon && <Icon className='icon' fill={isActiveLink(href) ? 'black' : 'white'} />}
                {name}
            </a>
        )
    }

    return (
        <div className='group'>
            {renderItem()}
            <div className='ml-6'>
                {navigation?.map((item, index) => {
                    return <SidebarListItem key={index} item={item} />
                })}
            </div>
        </div>
    )
}

export default SidebarListItem
