import { IconProps } from './Icon.types'
import React from 'react'

const InvoiceIcon = ({ fill }: IconProps) => {
    return (
        <svg
            className='mr-3 h-[20px] w-[20px]'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.90624 11.1052C9.44694 10.9355 10.0819 11.2825 10.6045 12.0335L12.1436 10.9626C11.6288 10.2228 11.021 9.70411 10.337 9.42103C10.2666 9.39192 10.196 9.36609 10.125 9.34242V7.3125H8.25003V9.348C7.12442 9.74466 6.37503 10.7978 6.37503 12C6.37503 13.5508 7.63672 14.8125 9.18753 14.8125C9.70447 14.8125 10.125 15.2331 10.125 15.75C10.125 16.1621 9.86131 16.5217 9.46883 16.6448C8.9278 16.8145 8.29321 16.4675 7.77069 15.7167L6.23169 16.7877C6.74647 17.5275 7.3543 18.046 8.03825 18.3291C8.10847 18.3581 8.17916 18.3836 8.25003 18.4072V20.4375H10.125V18.402C11.2506 18.0053 12 16.9521 12 15.75C12 14.1992 10.7383 12.9375 9.18753 12.9375C8.6706 12.9375 8.25003 12.5169 8.25003 12C8.24999 11.5879 8.51371 11.2283 8.90624 11.1052Z'
                fill={fill}
            />
            <path
                d='M15.2008 0H5.4375C3.88669 0 2.625 1.26169 2.625 2.8125V21.1875C2.625 22.7383 3.88669 24 5.4375 24H18.5625C20.1133 24 21.375 22.7383 21.375 21.1875V6.17419L15.2008 0ZM15.75 3.20081L18.1742 5.625H16.6875C16.1706 5.625 15.75 5.20444 15.75 4.6875V3.20081ZM18.5625 22.125H5.4375C4.92056 22.125 4.5 21.7044 4.5 21.1875V2.8125C4.5 2.29556 4.92056 1.875 5.4375 1.875H13.875V4.6875C13.875 6.23831 15.1367 7.5 16.6875 7.5H19.5V21.1875C19.5 21.7044 19.0794 22.125 18.5625 22.125Z'
                fill={fill}
            />
            <path d='M13.875 11.0625H17.625V12.9375H13.875V11.0625Z' fill={fill} />
            <path d='M13.875 14.8125H17.625V16.6875H13.875V14.8125Z' fill={fill} />
            <path d='M13.875 18.5625H17.625V20.4375H13.875V18.5625Z' fill={fill} />
        </svg>
    )
}

export default InvoiceIcon
