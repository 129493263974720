import { AxiosProviderProps } from './AxiosContext.types'
import { toast } from 'react-toastify'
import AxiosContext from './AxiosContext'
import React, { useMemo } from 'react'
import axiosLib, { AxiosInstance } from 'axios'

const AxiosProvider = ({ children, baseUrl }: AxiosProviderProps) => {
    const axios = useMemo<AxiosInstance>(() => {
        const instance = axiosLib.create({
            baseURL: baseUrl
        })

        //Show toast on error
        instance.interceptors.response.use(undefined, error => {
            if (axiosLib.isAxiosError(error) && error.response?.status === 500) {
                toast.error('Chyba serveru')
            }
            if (axiosLib.isAxiosError(error) && error?.response?.data.message) {
                const message = error.response.data.message
                const responseURL = error.response.request.responseURL
                //TODO make list of disabled controllers
                if (!/^https?:\/\/.*\/order\/\d+\/item(?:\/\d+)?$/.test(responseURL)) {
                    toast.error(message)
                }
            }
            return Promise.reject(error)
        })

        return instance
    }, [baseUrl])

    return <AxiosContext.Provider value={{ axios }}>{children}</AxiosContext.Provider>
}

export default AxiosProvider
